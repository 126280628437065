import React, {useState, useEffect} from 'react';
import Moment from 'react-moment';
import {observer} from 'mobx-react-lite';
// import FacebookLogin from 'react-fb-social-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {Row, Col, Button, Form, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {useStore} from '../../../store';
import {
  SettingsGroup,
  UpdateButton,
  UpdateModal,
  UpgradeModal,
  LabelDiv,
  ValueDiv,
  UpdateLink,
  SubscriptionStatus,
  CancelSubscription,
} from '../elements/profile';
import {setAccount} from '../../../api/account';
import Switch from '../../../components/Switch';
import AlertSkipTrialModal from './alertSkipTrial';
import {amplitude} from '../../../utils/Amplitude';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Settings = observer(() => {
  const location = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register: passwordRegister,
    handleSubmit: passwordSubmit,
    watch: passwordWatch,
    errors: passwordErrors,
  } = useForm();
  /* Personal modal state */
  const [showPersonalModal, setShowPersonalModal] = useState(false);
  /* Account modal state */
  const [showAccountModal, setShowAccountModal] = useState(false);
  /* Payment modal state */
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const [isFormSaving, setFormSaving] = useState(false);

  const [isReactivating, setIsReactivating] = useState(false);

  const [paymentError, setPaymentError] = useState('');

  const [userPlan, setUserPlan] = useState(false);

  const [userStatus, setUserStatus] = useState('');

  const [accessEndDate, setAccessEndDate] = useState(false);

  const [alertModalShow, setAlertModalShow] = useState(false);

  const [price, setPrice] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  // handle forms

  const {authStore, accountStore, subscriptionStore} = useStore();
  const {
    isGoogleConnected,
    // isFacebookConnected,
    socialConnectionStatus,
    socialConnectionStatusText,
    onSocialConnect,
    checkSocialConnectionStatus,
    professionList,
    specialtyList,
    getAllLists,
    selectedProduct,
  } = authStore;
  const {
    account,
    paymentDetails,
    isAccountLoading,
    userSettings,
    personalInfoSubmitted,
    getAccount,
    userSettingsChanged,
    userInfoChanged,
    reactivateSubscription,
  } = accountStore;
  const {setPaymentMethod, startSubscribing, updatePaymentDetails, skipTrialSubscription} = subscriptionStore;

  const {register, handleSubmit, errors} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // defaultValues: {
    //   profession_id: account?.profession_id,
    //   specialty_id: account?.specialty_id,
    // other_profession: account?.extra_data?.other_profession,
    // other_specialty: account?.extra_data?.other_specialty,
    // },
  });

  const handlePersonalModalClose = () => setShowPersonalModal(false);
  const handlePersonalModalShow = () => setShowPersonalModal(true);
  const handleAccountModalClose = () => setShowAccountModal(false);
  const handleAccountModalShow = () => setShowAccountModal(true);
  const handlePaymentModalClose = () => setShowPaymentModal(false);
  const handlePaymentModalShow = () => setShowPaymentModal(true);
  const handleUpgradePlanModalClose = () => setShowUpgradePlanModal(false);
  const handleUpgradePlanModalShow = () => setShowUpgradePlanModal(true);

  // const watchProfField = watch('profession_id');
  // const watchSpecField = watch('specialty_id');

  useEffect(() => {
    checkSocialConnectionStatus();
  }, [checkSocialConnectionStatus]);

  useEffect(() => {
    if (personalInfoSubmitted) {
      setFormSaving(false);
      setShowPersonalModal(false);
    }
    if (!professionList?.length || !specialtyList?.length) {
      setIsLoading(true);
      getAllLists(() => {
        setIsLoading(false);
      });
    }
    if (professionList.length && specialtyList.length) setIsLoading(false);
  }, [errors, professionList, specialtyList, personalInfoSubmitted, account, getAllLists]);
  useEffect(() => {
    if (account.subscription?.name) setUserPlan(account.subscription.name);
    else setUserPlan('No Active Plan');

    if (account.subscription && account.subscription.status) {
      if (account.subscription?.is_cancelled) {
        if (account.subscription.status === 'cancelled') setUserStatus('Cancelled');
        else setUserStatus('Active');
      } else if (account.subscription.status.length > 1) {
        setUserStatus(account.subscription.status.charAt(0).toUpperCase() + account.subscription.status.slice(1));
      }
    }
  }, [account.subscription, account, account.subscription?.status, account.subscription?.is_cancelled]);

  useEffect(() => {
    if (!account) {
      getAccount();
    }
    if (account?.access?.AccessHistory?.extended_date) setAccessEndDate(account?.access?.AccessHistory?.extended_date);
    else if (account?.access?.access_period_days) {
      const accessDate = new Date(account?.access?.AccessHistory?.created_at.slice(0, 19));
      accessDate.setDate(accessDate.getDate() + account?.access?.access_period_days);
      setAccessEndDate(accessDate);
    } else setAccessEndDate(account?.access?.end_date);

    const subscriptionPrice = account?.subscription?.subscription_price || 0;
    const giftcardPrice = account?.subscription?.giftcard_price || 0;
    const percent_off = account?.subscription?.percent_off || 0;
    const total = giftcardPrice + subscriptionPrice * (1 - percent_off / 100);
    setPrice(total.toFixed(2));
    return null;
  }, [account, getAccount]);

  // const responseFacebook = response => {
  //   const socialLoginType = 'facebook';
  //   onSocialConnect(response, socialLoginType);
  // };
  const responseGoogle = response => {
    const socialLoginType = 'google';
    if (response) onSocialConnect(response, socialLoginType);
  };

  const getProfessionName = () => {
    const profession = professionList?.find(p => p.id === Number(account?.profession_id));
    let displayName = profession?.display_name;
    if (profession?.specialty_type === 'other') {
      displayName = account?.extra_data?.other_profession;
    }
    return displayName;
  };

  const getSpecialtyName = () => {
    let specialty = null;
    if (account?.specialty_id) specialty = specialtyList?.find(p => p.id === Number(account?.specialty_id));
    else if (account?.specialties[0]) specialty = specialtyList?.find(p => p.id === Number(account?.specialties[0].id));
    let displayName = specialty?.display_name;
    if (specialty?.type === 'other') {
      displayName = account?.extra_data?.other_specialty;
    }
    return displayName;
  };

  const onClickUpgradePlan = () => {
    if (
      [
        'Premium 3 Year Plan',
        'Premium 2 Year Plan',
        'Premium Annual Plan',
        'GIBLIB Premium 3 Year Plan',
        'GIBLIB Premium 2 Year Plan',
        'GIBLIB Premium Annual Plan',
      ].includes(account?.subscription?.name)
    ) {
      handleUpgradePlanModalShow();
    } else {
      location.push(`/profile/plan-update`);
    }
  };

  const handlePersonalInfoFormSubmit = formData => {
    setFormSaving(true);
    userInfoChanged(formData);
  };

  const onUserSettingsChanged = (key, val) => {
    userSettingsChanged(key, val);
  };

  const handleAccountInfoFormSubmit = async ({password}) => {
    setFormSaving(true);
    const res = await setAccount({password});
    const status = res?.data?.status;
    setFormSaving(false);
    if (status) {
      getAccount();
      setShowAccountModal(false);
    }
  };

  const handlePaymentFormSubmit = async () => {
    setFormSaving(true);
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardNumberElement = elements.getElement(CardNumberElement);
      startSubscribing();
      setPaymentError(null);

      // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod: pMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      setPaymentMethod(pMethod);
      updatePaymentDetails();
      setFormSaving(false);
      handlePaymentModalClose();

      if (error) {
        // console.log('[createPaymentMethod error]', error);
        // setErrorToDisplay(error && error.message);
        throw error;
      }
    } catch (error) {
      setPaymentError(error && (error.message || (error.error && error.error.decline_code)));
    }
  };

  const handleReactivation = async () => {
    try {
      setIsReactivating(true);
      await reactivateSubscription();
      setIsReactivating(false);
    } catch (err) {
      setIsReactivating(false);
    }
  };

  const skipTrial = () => {
    skipTrialSubscription();
    setAlertModalShow(false);
  };

  const openAlertModal = () => {
    setAlertModalShow(true);
  };

  const closeAlertModal = () => {
    setAlertModalShow(false);
  };

  const CancellationStarted = () => {
    amplitude.getInstance().logEvent('cancellation-started');
  };

  // const renderSpecialtyForm = () => {
  //   if (watchProfField || account.profession_id) {
  //     const selectedProfession = professionList?.find(p => p.id === (+watchProfField || Number(account.profession_id)));
  //     const selectedSpeciality = specialtyList?.find(s => s.id === (+watchSpecField || Number(account.specialty_id)));
  //     setProfessionFields(selectedProfession);
  //     if (selectedProfession?.specialty_type === 'other') {
  //       return (
  //         <Form.Group controlId="select-extra_data.other_profession">
  //           <Form.Label>Please specify</Form.Label>
  //           <Form.Control
  //             type="text"
  //             placeholder="Please type your profession"
  //             name="extra_data.other_profession"
  //             className="rounded-pill pl-3 step-field"
  //             ref={register({required: true})}
  //             defaultValue={account.extra_data?.other_profession}
  //             custom
  //             isInvalid={!!errors.other_profession}
  //           />
  //           {errors.other_profession && (
  //             <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
  //           )}
  //         </Form.Group>
  //       );
  //     }
  //     return (
  //       selectedProfession?.specialty_type && (
  //         <>
  //           <Form.Group controlId="select-specialty">
  //             <Form.Label>
  //               {selectedProfession.specialty_type === 'education' ? 'Education Program' : 'Specialty'}*
  //             </Form.Label>
  //             <Form.Control
  //               size="sm"
  //               as="select"
  //               className="rounded-pill pl-3 step-field"
  //               name="specialty_id"
  //               defaultValue={account?.specialty_id}
  //               ref={register({required: true})}
  //               custom
  //               isInvalid={!!errors.specialty_id}
  //             >
  //               <option value="">Choose your specialty</option>
  //               {specialtyList
  //                 .filter(s => s.type === selectedProfession?.specialty_type || s.type === 'other')
  //                 .map(item => (
  //                   <option key={item?.id} value={item?.id}>
  //                     {item.display_name}
  //                   </option>
  //                 ))}
  //             </Form.Control>
  //             {errors.specialty_id && (
  //               <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
  //             )}
  //           </Form.Group>
  //           {selectedSpeciality?.id === 52 ? (
  //             <Form.Group controlId="select-extra_data-other_specialty">
  //               <Form.Label>
  //                 Please specify your{' '}
  //                 {selectedProfession.specialty_type === 'education' ? 'Education Program' : 'Specialty'}
  //               </Form.Label>
  //               <Form.Control
  //                 type="text"
  //                 placeholder="Please type your specialty here"
  //                 name="extra_data.other_specialty"
  //                 className="rounded-pill pl-3 py-2 step-field"
  //                 ref={register({required: true})}
  //                 defaultValue={account?.extra_data?.other_specialty}
  //                 custom
  //                 isInvalid={!!errors.other_specialty}
  //               />
  //               {errors.other_specialty && (
  //                 <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
  //               )}
  //             </Form.Group>
  //           ) : null}
  //         </>
  //       )
  //     );
  //   }
  //   return null;
  // };
  return (
    <>
      <Row>
        <Col lg={6}>
          <SettingsGroup className="mb-3">
            <div className="settings-group-header">
              <div className="settings-group-header-label">Personal Information</div>
              <UpdateButton
                onClick={handlePersonalModalShow}
                id="open-personal-info-modal"
                className="a_openPersonalInfoModalButton"
              >
                Update
              </UpdateButton>
            </div>
            <div className="d-flex flex-row flex-wrap">
              <div className="w-50">
                <LabelDiv>Name</LabelDiv>
                <ValueDiv id="user-fullname">
                  {!isAccountLoading ? [account?.first_name, account?.last_name].join(' ') : <Skeleton width={100} />}
                </ValueDiv>
              </div>
              <div className="w-50">
                <LabelDiv>Email</LabelDiv>
                <ValueDiv id="user-email">{!isAccountLoading ? account?.email : <Skeleton width={120} />}</ValueDiv>
              </div>
              <div className="w-50">
                <LabelDiv>Profession</LabelDiv>
                <ValueDiv id="user-profession">
                  {!isAccountLoading ? getProfessionName() : <Skeleton width={100} />}
                </ValueDiv>
              </div>
              {professionList?.find(p => p.id === Number(account?.profession_id))?.specialty_type !== 'other' &&
                professionList?.find(p => p.id === Number(account?.profession_id))?.specialty_type && (
                  <div className="w-50">
                    <LabelDiv>
                      {professionList?.find(p => p.id === Number(account?.profession_id))?.display_name === 'Student'
                        ? 'Education Program'
                        : 'Specialty'}
                    </LabelDiv>
                    {account.specialty?.display_name ? (
                      <ValueDiv id="user-specialty">
                        {!isAccountLoading ? account?.specialty?.display_name : <Skeleton width={100} />}
                      </ValueDiv>
                    ) : (
                      <ValueDiv id="user-specialty">
                        {!isAccountLoading ? getSpecialtyName() : <Skeleton width={100} />}
                      </ValueDiv>
                    )}
                  </div>
                )}
            </div>
          </SettingsGroup>
          <SettingsGroup className="mb-3">
            <div className="settings-group-header">
              <div className="settings-group-header-label">Account Information</div>
              <UpdateButton
                onClick={handleAccountModalShow}
                id="open-account-info-modal"
                className="a_openAccountInfoModalButton"
              >
                Update
              </UpdateButton>
            </div>
            <div>
              <LabelDiv>Password</LabelDiv>
              <ValueDiv>. . . . . . . . . . .</ValueDiv>
            </div>
          </SettingsGroup>
          <SettingsGroup className="mb-3">
            <div className="settings-group-header-label">Notifications</div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ValueDiv>General</ValueDiv>
              <Switch
                value={userSettings?.is_general_notification_allowed}
                onChange={val => onUserSettingsChanged('is_general_notification_allowed', val)}
                name="general-switch"
                id="general-switch"
                size="sm"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ValueDiv>Following</ValueDiv>
              <Switch
                value={userSettings?.is_following_notification_allowed}
                onChange={val => onUserSettingsChanged('is_following_notification_allowed', val)}
                name="following-switch"
                id="following-switch"
                size="sm"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <ValueDiv>New Content</ValueDiv>
              <Switch
                value={userSettings?.is_content_notification_allowed}
                onChange={val => onUserSettingsChanged('is_content_notification_allowed', val)}
                name="new-content-switch"
                id="new-content-switch"
                size="sm"
              />
            </div>
          </SettingsGroup>
          <SettingsGroup className="mb-3">
            <div className="settings-group-header">
              <div className="settings-group-header-label">Social Accounts</div>
            </div>
            <div>
              <div className="d-flex align-items-center flex-wrap">
                {/* {isFacebookConnected ? (
                  <div
                    className="mb-2 a_ProfileConnectedToFacebook d-flex justify-content-center mx-2"
                    id="profile-ConnectedTo-Facebook"
                  >
                    <p className="a_ProfileConnectedToFacebookText mb-0" id="profile-ConnectedTo-Facebook-Text">
                      Connect Facebook
                    </p>
                  </div>
                ) : (
                  <div className="mr-2 mb-2 a_profileConnectFacebook">
                    <FacebookLogin
                      appId={process.env.REACT_APP_FB_APP_ID}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      id="profile-connect-facebook"
                      className="a_profileConnectFacebookButton"
                      render={renderProps => (
                        <button
                          onClick={renderProps.onClick}
                          type="button"
                          id="profile-connect-facebook"
                          className="a_profileConnectFacebookButton"
                        >
                          Connect Facebook
                        </button>
                      )}
                    />
                  </div>
                )} */}
                {isGoogleConnected ? (
                  <div
                    className="mb-2 a_ProfileConnectedToGoogle d-flex justify-content-center"
                    id="profile-ConnectedTo-Google"
                  >
                    <p className="a_ProfileConnectedToGoogleText mb-0" id="profile-ConnectedTo-Google-Text">
                      Connect Google
                    </p>
                  </div>
                ) : (
                  <div className="mb-2 a_profileConnectGoogle">
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      render={renderProps => (
                        <button
                          {...renderProps}
                          type="button"
                          id="profile-connect-google"
                          className="a_profileConnectGoogleButton"
                        >
                          Connect Google
                        </button>
                      )}
                      buttonText="LOGIN WITH GOOGLE"
                      onSuccess={() => responseGoogle()}
                      onFailure={() => responseGoogle()}
                      id="profile-connect-google"
                      className="a_profileConnectGoogleButton"
                    />
                  </div>
                )}
              </div>
              <p
                className={classNames('mb-0 ml-2 ', {
                  'text-success a_profileConnectionSuccessText': socialConnectionStatus,
                  'text-danger a_profileConnectionFailedText': !socialConnectionStatus,
                })}
                id="profile-connection-response-text"
              >
                {socialConnectionStatusText}
              </p>
            </div>
          </SettingsGroup>
        </Col>
        <Col lg={6}>
          <SettingsGroup className="mb-3">
            <div className="settings-group-header">
              <div className="settings-group-header-label">Subscription</div>
              <UpdateLink
                onClick={() => onClickUpgradePlan()}
                id="open-subscription-info"
                className="a_openSubscriptionInfoModalButton"
              >
                Update
              </UpdateLink>
            </div>
            <div className="d-flex flex-row flex-wrap">
              <div className="w-50">
                <LabelDiv>Plan</LabelDiv>
                <ValueDiv className="text-capitalize">
                  {!isAccountLoading ? <p>{userPlan}</p> : <Skeleton width={135} />}
                </ValueDiv>
              </div>
              <div className="w-50">
                <LabelDiv>Status</LabelDiv>
                <ValueDiv>
                  {!isAccountLoading ? (
                    <SubscriptionStatus>
                      <p
                        className={classNames('mb-0', {
                          'text-danger': userStatus === 'Cancelled',
                        })}
                      >
                        {userStatus}
                      </p>
                      {userStatus === 'Trialing_no_skip_trial' ? (
                        <Button variant="primary" id="skip-trial" className="ml-5 a_skipTrial" onClick={openAlertModal}>
                          Skip Trial
                        </Button>
                      ) : (
                        <></>
                      )}
                      {alertModalShow ? (
                        <AlertSkipTrialModal
                          show={alertModalShow}
                          dollar={`$${price}`}
                          onConfirm={skipTrial}
                          onHide={closeAlertModal}
                          profilePage
                        />
                      ) : null}
                    </SubscriptionStatus>
                  ) : (
                    <Skeleton width={80} />
                  )}
                </ValueDiv>
              </div>
              {account.subscription && (
                <div className="w-50">
                  <LabelDiv>Last Payment</LabelDiv>
                  <ValueDiv>
                    {!isAccountLoading ? (
                      <Moment format="MM/DD/YYYY">{account.subscription?.last_payment}</Moment>
                    ) : (
                      <Skeleton width={80} />
                    )}
                  </ValueDiv>
                </div>
              )}
              {(account?.subscription?.name?.includes('2 Year') || account?.subscription?.name?.includes('3 Year')) &&
              userStatus.toLowerCase() === 'active' ? (
                <div className="w-50">
                  <LabelDiv>Subscription Ends</LabelDiv>
                  <ValueDiv>
                    {!isAccountLoading ? (
                      <Moment format="MM/DD/YYYY">{account.subscription?.cancel_at}</Moment>
                    ) : (
                      <Skeleton width={80} />
                    )}
                  </ValueDiv>
                </div>
              ) : (
                <div className="w-50">
                  {account.subscription && (
                    <>
                      <LabelDiv>{account.subscription?.is_cancelled ? 'Subscription Ends' : 'Next Payment'}</LabelDiv>
                      <ValueDiv>
                        {isAccountLoading && <Skeleton width={80} />}
                        {!isAccountLoading &&
                          (account.subscription?.is_cancelled ? (
                            <>
                              <span>Cancelled</span>
                              <Button
                                variant="primary"
                                id="reactivate"
                                data-testid="reactivate-subscription"
                                className="ml-3 a_reactivate"
                                onClick={handleReactivation}
                                disabled={isReactivating}
                              >
                                {isReactivating ? 'Reactivating...' : 'Reactivate'}
                              </Button>
                            </>
                          ) : (
                            <Moment format="MM/DD/YYYY">{account.subscription?.next_payment}</Moment>
                          ))}
                      </ValueDiv>
                    </>
                  )}
                </div>
              )}
            </div>
          </SettingsGroup>
          {account.access ? (
            <SettingsGroup className="mb-3">
              <div className="settings-group-header">
                <div className="settings-group-header-label">Group Subscription</div>
              </div>
              <div className="d-flex flex-row flex-wrap">
                <div className="w-50">
                  <LabelDiv>Plan</LabelDiv>
                  <ValueDiv className="text-capitalize">
                    {!isAccountLoading ? <p>{account.access.plan_name}</p> : <Skeleton width={135} />}
                  </ValueDiv>
                </div>
                <div className="w-50">
                  <LabelDiv>Status</LabelDiv>
                  <ValueDiv>
                    {!isAccountLoading ? (
                      <SubscriptionStatus>
                        <p>{account.access.free_or_paid === 'trial' ? 'trial' : account.access?.status}</p>
                      </SubscriptionStatus>
                    ) : (
                      <Skeleton width={80} />
                    )}
                  </ValueDiv>
                </div>
                <div className="w-50">
                  <LabelDiv>Start Date</LabelDiv>
                  <ValueDiv>
                    {!isAccountLoading ? (
                      <Moment format="MM/DD/YYYY">
                        {account.access.access_period_days
                          ? account.access.AccessHistory.created_at
                          : account.access.start_date}
                      </Moment>
                    ) : (
                      <Skeleton width={80} />
                    )}
                  </ValueDiv>
                </div>
                <div className="w-50">
                  <LabelDiv>End Date</LabelDiv>
                  <ValueDiv>
                    {!isAccountLoading ? <Moment format="MM/DD/YYYY">{accessEndDate}</Moment> : <Skeleton width={80} />}
                  </ValueDiv>
                </div>
              </div>
            </SettingsGroup>
          ) : null}
          {selectedProduct?.name === 'Free Plan' && (
            <SettingsGroup className="mb-3">
              <div className="settings-group-header">
                <div className="settings-group-header-label">Payment Detail</div>
                <UpdateButton
                  onClick={handlePaymentModalShow}
                  id="open-payment-info"
                  className="a_openPaymentInfoModalButton"
                >
                  Update
                </UpdateButton>
              </div>
              <div className="d-flex flex-row flex-wrap">
                <div className="w-50">
                  <LabelDiv>Card</LabelDiv>
                  <ValueDiv>
                    {!isAccountLoading && paymentDetails?.card?.last4 && `*${paymentDetails?.card?.last4}`}
                  </ValueDiv>
                </div>
              </div>
            </SettingsGroup>
          )}
        </Col>
      </Row>
      {account.subscription && !account.subscription.is_cancelled ? (
        <CancelSubscription
          href="/cancel/reason"
          id="open-cancel-subscription"
          className="a_openCancelSubscriptionButton"
          onClick={CancellationStarted}
        >
          Cancel Subscription
        </CancelSubscription>
      ) : (
        <></>
      )}

      {/* Update Modal for Personal Information */}
      <UpdateModal show={showPersonalModal} onHide={handlePersonalModalClose}>
        <UpdateModal.Header closeButton>Personal Information</UpdateModal.Header>
        <div className="account-email">
          <div className="account-email-header-label">Account Email</div>
          <strong>{account.email}</strong>
        </div>
        <UpdateModal.Body>
          <Form onSubmit={handleSubmit(handlePersonalInfoFormSubmit)}>
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    ref={register({required: true})}
                    isInvalid={errors.first_name}
                    defaultValue={account.first_name}
                    onChange={e => e.target.value}
                  />
                  <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    ref={register({required: true})}
                    isInvalid={errors.last_name}
                    defaultValue={account.last_name}
                    onChange={e => e.target.value}
                  />
                  <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {isLoading ? (
                  <div style={{width: '20rem'}} className="d-flex justify-content-center align-items-center p-5 h-100">
                    <Spinner animation="grow" />
                  </div>
                ) : (
                  <>
                    <Form.Group controlId="select-profession">
                      <Form.Label>Profession*</Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        className="rounded-pill pl-3 step-field"
                        name="profession_id"
                        defaultValue={account?.profession_id}
                        ref={register({required: true})}
                        custom
                        isInvalid={!!errors.profession_id}
                      >
                        <option value="">Choose your profession</option>
                        {professionList?.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.display_name}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.profession_id && (
                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </>
                )}
              </Col>
              <Col md={6} />
            </Row>
            <Form.Row>
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  variant="primary"
                  id="save-personal-info"
                  className="a_savePersonalInfoButton"
                  disabled={isFormSaving}
                >
                  {isFormSaving ? (
                    <>
                      <Spinner className="mr-2" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      Saving..
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form.Row>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>

      {/* Update Modal for Account Information */}
      <UpdateModal show={showAccountModal} onHide={handleAccountModalClose}>
        <UpdateModal.Header closeButton>Account Information</UpdateModal.Header>
        <UpdateModal.Body>
          <Form onSubmit={passwordSubmit(handleAccountInfoFormSubmit)}>
            <Row>
              <Col>
                <Form.Group controlId="formNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="password"
                    name="password"
                    placeholder="New Password"
                    isInvalid={passwordErrors?.password}
                    ref={passwordRegister({
                      required: 'This field is required.',
                    })}
                  />
                  <Form.Control.Feedback type="invalid">{passwordErrors?.password?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    isInvalid={passwordErrors?.confirm_password}
                    ref={passwordRegister({
                      required: 'This field is required.',
                      validate: value => value === passwordWatch('password') || "Passwords don't match.",
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {passwordErrors?.confirm_password?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Row>
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  variant="primary"
                  id="save-account-info"
                  className="a_saveAccountInfoButton"
                  disabled={isFormSaving}
                >
                  {isFormSaving ? (
                    <>
                      <Spinner className="mr-2" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      Saving..
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form.Row>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>

      {/* Update Modal for Payment Information */}
      <UpdateModal show={showPaymentModal} onHide={handlePaymentModalClose} className="paymentInfoModal">
        <UpdateModal.Header closeButton>Payment Information</UpdateModal.Header>
        <UpdateModal.Body>
          <Form onSubmit={handleSubmit(handlePaymentFormSubmit)}>
            <Row id="payment-form">
              <Form.Group as={Col} xs={6}>
                <Form.Label>
                  <small>Card Number*</small>
                </Form.Label>
                <CardNumberElement
                  options={{showIcon: true}}
                  id="payment-card-number-element"
                  className="form-control pt-2 bg-light rounded-pill"
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  <small>EXP*</small>
                </Form.Label>
                <CardExpiryElement
                  id="payment-card-expiry-element"
                  className="form-control pt-2 bg-light rounded-pill"
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  <small>CVC*</small>
                </Form.Label>
                <CardCvcElement id="payment-card-cvc-element" className="form-control pt-2 bg-light rounded-pill" />
              </Form.Group>
            </Row>
            <div className="text-danger mt-2" role="alert">
              {paymentError || null}
            </div>
            <Form.Row>
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  variant="primary"
                  id="save-account-info"
                  className="a_saveAccountInfoButton"
                  disabled={isFormSaving}
                >
                  {isFormSaving ? (
                    <>
                      <Spinner className="mr-2" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      Saving..
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form.Row>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>

      {/* Upgrade plan Modal */}
      <UpgradeModal show={showUpgradePlanModal} onHide={handleUpgradePlanModalClose} className="planUpgradeInfoModal">
        <UpgradeModal.Header closeButton>Manage Plan</UpgradeModal.Header>
        <UpgradeModal.Body>
          <div>
            <p className="a_upgradeModalContent">
              If you want to change your current plan or have questions, please contact Customer Support at{' '}
              <span className="a_upgradePlanEmail">
                <a href="mailto:support@giblib.com">support@giblib.com</a>
              </span>
              .
            </p>
          </div>
          <div className="w-100 d-flex flex-row-reverse">
            <Button
              type="submit"
              variant="primary"
              id="close-upgrade-info"
              className="a_closeUpgradeInfoButton"
              onClick={handleUpgradePlanModalClose}
            >
              Close
            </Button>
          </div>
        </UpgradeModal.Body>
      </UpgradeModal>
    </>
  );
});

export default () => (
  <Elements stripe={stripePromise}>
    <Settings />
  </Elements>
);
